<template>
    <div class="console">
        <consoleNavbar/>
    
        <router-view/>
    </div>

    <imageViewer v-if="useModals.imageViewer.show"/>
</template>

<script>
import consoleNavbar from '@/components/consoleNavbar.vue'
import imageViewer from '@/modals/imageViewer.vue'

// import { useUpdates } from '@/store/updates'
import { useModals } from '@/store/modals'

// import { urls, get } from '@/store/crud'
// import { deepCopy } from '@/store/util'

export default {
    components: {
        consoleNavbar,
        imageViewer,
    },
    data: () => ({
        // useUpdates:useUpdates(),
        useModals:useModals(),
    }),
    created(){
        // this.loadUpdates()
    },
    methods:{
        // async loadUpdates(){
        //     this.useUpdates.status = false
        //     const data = await get(`${urls.updates}`)

        //     if (data.code) return

        //     this.useUpdates.tablas = deepCopy(data)
        //     this.useUpdates.status = true
        // }
    }
}
</script>

<style lang="scss">
.console{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: grid;
    grid-template-columns: 15rem calc(100% - 15rem);
    grid-template-rows: 100vh;
    background-color: var(--bg-color2);
}

.container-buscar{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.container-lista{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;

    li{
        padding: 1rem;
        border-radius: 0.5rem;
        cursor: pointer;

        &:hover{
            background-color: var(--hover-color);
        }
    }
}

.container-lista2{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    overflow-y: auto;

    li{
        padding: 0.5rem;
        border-radius: 0.3rem;
        cursor: pointer;

        &:hover{
            background-color: var(--hover-color);
        }
    }

    .selected{
        border: dashed 0.06rem var(--primary-color);
    }
}

.botones{
    display: flex;
    justify-content: right;
    gap: 0.5rem;
}

.crear{
    .crear-pestanas{
        display: flex;
        padding: 0.2rem 1rem 0;
        background-color: var(--bg-color2);

        li{
            padding: 0.5rem;
            cursor: pointer;
        }

        .pestana-actual{
            background-color: var(--bg-color);
        }
    }

    .pestanas-inside{
        padding: 0 1rem 2rem 1rem;
        border-bottom: var(--border);
    }

    .crear-footer{
        display: flex;
        justify-content: right;
        gap: 0.5rem;
        margin-top: 2rem;
    }

    .form-data{
        display: grid;
        gap: 0.5rem;
    }

    .datonec{
        &::after{
            content: ' *';
            color: red;
            font-weight: bold;
        }
    }

    .datonec2{
        &::after{
            content: ' *';
            color: blue;
            font-weight: bold;
        }
    }
}

.data-main{
    width: 40rem;
    max-width: 100%;
    padding: 1.3rem;
    background-color: var(--bg-color);
    border-radius: 0.5rem;
    box-shadow: var(--section-box-shadow);

    .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.max-2lines{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.max-3lines{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>