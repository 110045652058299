<template>
    <article class="crear">
        <header class="mrg-btm2">
            <h2>{{ useModals.dataServiciosCrear.title }}</h2>
        </header>

        <dataServiciosCrearGeneral/>

        <ul class="crear-pestanas mrg-btm1 mrg-top1">
            <li :class="{'pestana-actual':pestana==1}" @click="pestana=1">Paquetes</li>
            <li :class="{'pestana-actual':pestana==2}" @click="pestana=2">Es para ti</li>
            <li :class="{'pestana-actual':pestana==3}" @click="pestana=3">No es para ti</li>
            <li :class="{'pestana-actual':pestana==4}" @click="pestana=4">Proceso</li>
        </ul>

        <div class="pestanas-inside">
            <dataServiciosCrearPaquetes v-if="pestana == 1"/>
            <dataServiciosCrearParaTi   v-if="pestana == 2"/>
            <dataServiciosCrearParaTiNo v-if="pestana == 3"/>
            <dataServiciosCrearProceso  v-if="pestana == 4"/>
        </div>

        <div class="crear-footer">
            <buttonSpin text="Cancelar" :func="cancelar"/>
            <buttonSpin color="1" text="Crear" :func="crear" :spin="btnCrear" v-if="!useData.servicio.id"/>
            <buttonSpin text="Eliminar" :func="eliminar" :spin="btnEliminar" v-if="useData.servicio.id"/>
            <buttonSpin color="1" text="Modificar" :func="modificar" :spin="btnModificar" v-if="useData.servicio.id"/>
        </div>
    </article>
</template>

<script>
import dataServiciosCrearGeneral from '@/views/servicios/dataServiciosCrearGeneral.vue'
import dataServiciosCrearPaquetes from '@/views/servicios/dataServiciosCrearPaquetes.vue'
import dataServiciosCrearParaTi from '@/views/servicios/dataServiciosCrearParaTi.vue'
import dataServiciosCrearParaTiNo from '@/views/servicios/dataServiciosCrearParaTiNo.vue'
import dataServiciosCrearProceso from '@/views/servicios/dataServiciosCrearProceso.vue'
import buttonSpin from '@/components/buttonSpin.vue'

import { useAuth } from '@/store/auth'
import { useData } from '@/store/data'
import { useModals } from '@/store/modals'

import { urls, post, patch, delet } from '@/store/crud'
import { deepCopy, swal, incompleteData } from '@/store/util'

export default {
    components: {
        dataServiciosCrearGeneral,
        dataServiciosCrearPaquetes,
        dataServiciosCrearParaTi,
        dataServiciosCrearParaTiNo,
        dataServiciosCrearProceso,
        buttonSpin
    },
    data: () => ({
        pestana:1,

        btnCrear:false,
        btnModificar:false,
        btnEliminar:false,

        useAuth:useAuth(),
        useData:useData(),
        useModals:useModals(),
    }),
    unmounted() {
        this.cancelar()
    },
    methods: {
        cancelar(){
            this.useModals.dataServiciosCrear.show = false
        },
        checkDatos(){
            const item = deepCopy(this.useData.servicio)
            
            let llenos = ['name','info','uri','image','portada']

            if (incompleteData(item, llenos)) {
                swal('warning', 'Ingrese los datos necesarios (*)')
                return false
            }

            for (const a of item.para_ti)
            {
                if (incompleteData(a, ['text'])) {
                    swal('warning', 'Campos vacíos en "Es para ti"')
                    return false
                }
            }

            for (const a of item.para_ti_no)
            {
                if (incompleteData(a, ['text'])) {
                    swal('warning', 'Campos vacíos en "No es para ti"')
                    return false
                }
            }

            for (const a of item.proceso)
            {
                if (incompleteData(a, ['text'])) {
                    swal('warning', 'Campos vacíos en "Proceso"')
                    return false
                }
            }

            return true
        },
        async crear(){
            if (this.checkDatos() == false) return

            this.useData.servicio.created_by = this.useAuth.id,

            this.btnCrear = true
            const data = await post(`${urls.servicios}`, this.useData.servicio)
            this.btnCrear = false

            if (data.code != 0) return

            this.useData.servicio.id = data.id,
            this.useData.servicio.created_at = data.created_at,
            this.useData.servicio.last_update = data.last_update

            this.useData.servicios.push(this.useData.servicio)
            this.cancelar()
        },
        async modificar(){
            if (this.checkDatos() == false) return

            this.btnModificar = true
            const data = await patch(`${urls.servicios}`, this.useData.servicio)
            this.btnModificar = false

            if (data.code != 0) return
            
            this.useData.servicio.last_update = data.last_update

            const i = this.useData.servicios.findIndex(a => a.id == this.useData.servicio.id)
            this.useData.servicios.splice(i, 1, this.useData.servicio)
            this.cancelar()
        },
        async eliminar(){
            this.btnEliminar = true
            const data = await delet(`${urls.servicios}`, this.useData.servicio)
            this.btnEliminar = false

            if (data.code != 0) return

            const i = this.useData.servicios.findIndex(a => a.id == this.useData.servicio.id)
            this.useData.servicios.splice(i, 1)
            this.cancelar()
        },
    },
}
</script>