<template>
    <article class="crear">
        <header class="mrg-btm2">
            <h2>{{ useModals.dataInstagramPostsCrear.title }}</h2>
        </header>

        <div class="form-data" style="grid-template-columns: 10rem auto">
            <label>Link imagen</label>
            <inputImageLink v-model="useData.instagramPost.image"/>

            <label>Link publicación</label>
            <inputLinkOpen v-model="useData.instagramPost.link"/>
        </div>

        <div class="crear-footer">
            <buttonSpin text="Cancelar" :func="cancelar"/>
            <buttonSpin color="1" text="Crear" :func="crear" :spin="btnCrear" v-if="!useData.instagramPost.id"/>
            <buttonSpin text="Eliminar" :func="eliminar" :spin="btnEliminar" v-if="useData.instagramPost.id"/>
            <buttonSpin color="1" text="Modificar" :func="modificar" :spin="btnModificar" v-if="useData.instagramPost.id"/>
        </div>
    </article>
</template>

<script>
import buttonSpin from '@/components/buttonSpin.vue'
import inputImageLink from '@/components/inputImageLink.vue'
import inputLinkOpen from '@/components/inputLinkOpen.vue'

import { useAuth } from '@/store/auth'
import { useData } from '@/store/data'
import { useModals } from '@/store/modals'

import { urls, post, patch, delet } from '@/store/crud'
import { swal, incompleteData } from '@/store/util'

export default {
    components: {
        buttonSpin,
        inputImageLink,
        inputLinkOpen,
    },
    data: () => ({
        btnCrear:false,
        btnModificar:false,
        btnEliminar:false,

        useAuth:useAuth(),
        useData:useData(),
        useModals:useModals(),
    }),
    unmounted() {
        this.cancelar()
    },
    methods: {
        cancelar(){
            this.useModals.dataInstagramPostsCrear.show = false
        },
        async crear(){
            if (incompleteData(this.useData.instagramPost, ['image','link'])) return swal('warning', 'Ingrese el link del post y de la imagen')

            this.useData.instagramPost.created_by = this.useAuth.id,

            this.btnCrear = true
            const data = await post(`${urls.instagram}`, this.useData.instagramPost)
            this.btnCrear = false

            if (data.code != 0) return

            this.useData.instagramPost.id = data.id,
            this.useData.instagramPost.created_at = data.created_at,
            this.useData.instagramPost.last_update = data.last_update

            this.useData.instagramPosts.push(this.useData.instagramPost)
            this.cancelar()
        },
        async modificar(){
            if (incompleteData(this.useData.instagramPost, ['image','link'])) return swal('warning', 'Ingrese el link del post y de la imagen')

            this.btnModificar = true
            const data = await patch(`${urls.instagram}`, this.useData.instagramPost)
            this.btnModificar = false

            if (data.code != 0) return
            
            this.useData.instagramPost.last_update = data.last_update

            const i = this.useData.instagramPosts.findIndex(a => a.id == this.useData.instagramPost.id)
            this.useData.instagramPosts.splice(i, 1, this.useData.instagramPost)
            this.cancelar()
        },
        async eliminar(){
            this.btnEliminar = true
            const data = await delet(`${urls.instagram}`, this.useData.instagramPost)
            this.btnEliminar = false

            if (data.code != 0) return

            const i = this.useData.instagramPosts.findIndex(a => a.id == this.useData.instagramPost.id)
            this.useData.instagramPosts.splice(i, 1)
            this.cancelar()
        }
    },
}
</script>