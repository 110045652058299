<template>
    <div class="container-button">
        <button class="btn" :class="{btn1:color=='1', btn2:color=='2'}" @click="func">
            <span :class="{'hide-text': spin}">{{ text }}</span>
        </button>
        <loadingSpin v-if="spin" :color="color" scale="0.5"/>
    </div>
</template>

<script>
import loadingSpin from '@/components/loadingSpin.vue'

export default {
    components: {loadingSpin},
    props:{
        color: {type:String, default:'2'},
        text: {type:String, default:'Button'},
        func: Function,
        spin: {type:Boolean, default:false}
    },
    data: () => ({
        show:false
    })
}
</script>

<style lang="scss">
.container-button{
    position: relative;
    overflow: hidden;

    .hide-text{
        opacity: 0;
    }
}
</style>