import {swal} from "@/store/util"

const host = process.env.VUE_APP_HOSTBACKEND
const tokenmyapi = process.env.VUE_APP_TOKENMYAPI
const urls = {
    updates: `${host}/api/updates`,
    usuarios: `${host}/api/usuarios`,
    clientes: `${host}/api/clientes`,
    comentarios: `${host}/api/comentarios`,
    instagram: `${host}/api/instagram`,
    servicios: `${host}/api/servicios`,
}

let response

async function get(url){
    try {
        response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': tokenmyapi
            }
        })
    }
    catch (error) {
        console.log(error)
        swal('error', error)
        return {code:-2}
    }
    
    const data = await response.json()

    if (data.code == -1) {
        console.log(data)
        swal('error', 'Algo salió mal')
    }

    return data
}

async function post(url, item, ms){
    try {
        response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': tokenmyapi
            },
            body: JSON.stringify(item)
        })
    }
    catch (error) {
        console.log(error)
        swal('error', error)
        return {code:-2}
    }

    const data = await response.json()

    if (data.code == -1) {
        console.log(data)
        swal('error', 'Algo salió mal')
    }

    if (data.code > 0) swal('error', data.msg)

    if (data.code == 0) {
        if (ms != false) {
            swal('success', ms == undefined ? 'Creado con éxito' : ms)
        }
    }

    return data
}

async function patch(url, item){
    try{
        response = await fetch(`${url}/${item.id}`,{
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': tokenmyapi
            },
            body: JSON.stringify(item)
        })
    }
    catch (error) {
        console.log(error)
        swal('error', error)
        return {code:-2}
    }

    const data = await response.json()

    if (data.code == -1) {
        console.log(data.msg)
        swal('error', 'Algo salió mal')
    }

    if (data.code > 0) swal('error', data.msg)

    if (data.code == 0) {
        swal('success', 'Actualizado con éxito')
    }

    return data
}

async function delet(url, item, ms){
    try {
        response = await fetch(`${url}/${item.id}`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': tokenmyapi
            },
            body: JSON.stringify(item)
        })
    }
    catch (error) {
        console.log(error)
        swal('error', error)
        return {code:-2}    
    }

    const data = await response.json()

    if (data.code == -1) {
        console.log(data.msg)
        swal('error', 'Algo salió mal')
    }

    if (data.code > 0) swal('error', data.msg)

    if (data.code == 0) {
        if (ms != false) {
            swal('success', ms == undefined ? 'Eliminado con éxito' : ms)
        }
    }

    return data
}

export {urls, get, post, patch, delet}