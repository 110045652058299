import {defineStore} from "pinia"
// import {urls, get} from '@/utils/crud'

export const useAuth = defineStore('auth', {
    state: () => ({
        id: null,
        user: '',
        state: '',
        token: '',
        usuario: {},
        socket: null,
        device: ''
    }),
    actions: {
        // async verify(){
        //     const data = await get(`${urls.usuarios}?usuario=${this.user}`)
            
        //     if (data.length == 0) {
        //         this.logout()
        //         return false
        //     }

        //     if (data[0].token == this.token) {
        //         this.usuario = data[0]
        //         return true
        //     } else {
        //         this.logout()
        //         return false
        //     }
        // },
        // logout(){
        //     this.usuario = {}
        //     this.user = ''
        //     this.token = ''
        // },

        // detectDevice(){
        //     if(navigator.userAgent.toLowerCase().match(/mobile/)) {
        //         this.device = 3
        //     } else {
        //         if(navigator.userAgent.toLowerCase().match(/tablet/)) {
        //             this.device = 2
        //         } else {
        //             this.device = 1
        //         }
        //     }
        // }
    },
    persist: {
        storage: localStorage,
        paths: ['id','token']
    }
})