<template>
    <article>
        <div class="container-buscar mrg-btm1">
            <span>Buscar:</span>
            <input type="search" v-model="txtBuscar" @input="buscar()">
        </div>

        <ul class="container-lista">
            <li v-for="a in lista1" :key="a.id" @click="editar(a)">
                <img :src="a.image" loading="lazy">

                <div>
                    <div class="mrg-btm1">
                        <strong>{{ capitalizarPalabras(a.name) }}</strong>
                        <small>{{ moment(a.created_at).format('DD/MM/YYYY LT') }}</small>
                    </div>

                    <div class="mrg-btm1">
                        <small>Descripción</small>
                        <p class="max-3lines">{{ a.info }}</p>
                    </div>

                    <div>
                        <div>
                            <small>Paquetes</small>
                            <p>{{ a.packs.length }}</p>

                            <small>Proceso</small>
                            <p>{{ a.proceso.length }}</p>
                        </div>

                        <div>
                            <small>Es para ti</small>
                            <p>{{ a.para_ti.length }}</p>
                            <small>No es para ti</small>
                            <p>{{ a.para_ti_no.length }}</p>
                        </div>
                    </div>
                </div>
            </li>

            <small v-if="!listLoading && lista1.length==0">No se encuentran registros</small>

            <loadingSpin position="initial" v-if="listLoading"/>
        </ul>
    </article>
</template>

<script>
import loadingSpin from '@/components/loadingSpin.vue'

import { useData } from '@/store/data'
import { useModals } from '@/store/modals'

import { urls, get} from '@/store/crud'
import { deepCopy, capitalizarPalabras } from '@/store/util'

import moment from 'moment'

export default {
    components: {loadingSpin},
    data: () => ({
        lista1:[],
        txtBuscar:'',
        listLoading:false,

        useData:useData(),
        useModals:useModals(),

        capitalizarPalabras,

        moment,
    }),
    async mounted(){
        if (this.useData.serviciosLd) {
            await this.load()
            this.useData.serviciosLd = false
        }
        else {
            this.buscar()
        }
    },
    methods: {
        async load(){
            this.listLoading = true
            const data = await get(`${urls.servicios}`)
            this.listLoading = false

            if (data.code) return

            this.useData.servicios = deepCopy(data)

            this.buscar()
        },
        editar(a){
            this.useData.servicio = deepCopy(a)

            this.useModals.dataServiciosCrear.title = 'Editar servicio'
            this.useModals.dataServiciosCrear.show = true
        },
        buscar(){
            this.lista1 = this.useData.servicios.filter(a => {
                return a.name.toLowerCase().includes(this.txtBuscar.toLowerCase()) ||
                        a.info.toLowerCase().includes(this.txtBuscar.toLowerCase())

            })
        },
    },
}
</script>

<style lang="scss">
.container-lista{
    li{
        display: grid;
        grid-template-columns: 7rem auto;
        gap: 1rem;

        img{
            width: 100%;
        }

        >div{
            >div:nth-child(1){
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            >div:nth-child(2){
                display: flex;
                gap: 1rem;
            }

            >div:nth-child(3){
                display: flex;
                gap: 6rem;

                div{
                    display: grid;
                    align-items: flex-end;
                    gap: 0.5rem 1rem;
                    grid-template-columns: auto auto;
                }
            }
        }
    }
}
</style>