<template>
    <div class="form-data" style="grid-template-columns: 10rem auto">
        <label class="datonec">Nombre</label>
        <input type="text" v-model="useData.servicio.name">

        <label class="datonec">Descripción</label>
        <textarea rows="4" v-model="useData.servicio.info"></textarea>

        <label class="datonec">Url</label>
        <input type="text" v-model="useData.servicio.uri">

        <label class="datonec">Link imagen</label>
        <inputImageLink v-model="useData.servicio.image"/>

        <label class="datonec">Link portada</label>
        <inputImageLink v-model="useData.servicio.portada"/>
    </div>
</template>

<script>
import inputImageLink from '@/components/inputImageLink.vue'

import { useData } from '@/store/data'
import { useModals } from '@/store/modals'

export default {
    components: {
        inputImageLink,
    },
    data: () => ({
        useData:useData(),
        useModals:useModals(),
    }),
}
</script>