<template>
    <section class="data-main">
        <article class="head mrg-btm2" v-if="!useModals.dataInstagramPostsCrear.show">
            <h2>Instagram posts</h2>
            <button class="btn btn1" @click="nuevo">Nuevo</button>
        </article>
        
        <dataInstagramPostsLista v-if="!useModals.dataInstagramPostsCrear.show"/>
        <dataInstagramPostsCrear v-if="useModals.dataInstagramPostsCrear.show"/>
    </section>
</template>

<script>
import dataInstagramPostsLista from '@/views/home/dataInstagramPostsLista.vue'
import dataInstagramPostsCrear from '@/views/home/dataInstagramPostsCrear.vue'

import { useModals } from '@/store/modals'
import { useData } from '@/store/data'

export default {
    components: {
        dataInstagramPostsLista,
        dataInstagramPostsCrear
    },
    data: () => ({
        useModals:useModals(),
        useData:useData(),
    }),
    methods: {
        nuevo(){
            this.useData.initInstagramPost()
            this.useModals.dataInstagramPostsCrear.title = 'Nuevo post'
            this.useModals.dataInstagramPostsCrear.show = true
        },
    },
}
</script>