<template>
<section class="login">
    <article class="info">
        <img src="@/assets/img/logo.png">
    </article>

    <article class="form-login">
        <div class="card">
            <imgLogo class="mrg-btm2"/>

            <input type="text" placeholder="Usuario" required v-model="usuario" class="mrg-btm1">

            <inputPassword placeholder="Contraseña" v-model="contrasena" class="mrg-btm1"/>

            <inputCheckBox v-model="recordar" text="Recordarme" textSize="0.8" class="mrg-btm2"/>

            <buttonSpin color="1" text="Iniciar sesión" :func="login" :spin="spinBtnSignIn"/>
        </div>
        
        <!-- <div class="card">
            <p>
                ¿No tienes una cuenta? <router-link :to="{name:'signup'}">Registrate</router-link>
            </p>
        </div> -->
    </article>
</section>
</template>
  
<script>
import imgLogo from '@/components/imgLogo.vue'
import inputPassword from '@/components/inputPassword.vue'
import inputCheckBox from '@/components/inputCheckBox.vue'
import buttonSpin from '@/components/buttonSpin.vue'

import {useAuth} from '@/store/auth'
import {urls, post} from '@/store/crud'
import {swal} from '@/store/util'

export default {
    components: {imgLogo, inputPassword, inputCheckBox, buttonSpin},
    data: () => ({
        spinBtnSignIn:false,
        useAuth:useAuth(),

        recordar: true,
        usuario: '', contrasena: '', token: '',
        shown: false,
    }),
    mounted() {
        // this.islogged()
        this.usuario = localStorage.getItem('login_remember')
    },
    methods: {
        async islogged() {
            console.log(this.recordar)
            // const data = await get(urls.usuarios)

            // if (data.length == 0) {
            //     console.log(data)
            // }
            // else {
            //     console.log('vacio')
            // }


            // let us = localStorage.getItem('user')
            // let tk = localStorage.getItem('token')

            // if (!us || !tk) return this.pinia.logout()

            // const data = await this.pinia.getData(`${urls.usuarios}/u/${us}`, 'Comprobando sesión ...')

            // if (data.length == 0) {
            //     this.pinia.logout()
            // } else {
            //     if (data[0].token === tk) {
            //         msg('success', 'Tiene su cuenta abierta')
            //         this.manageLog(data[0])
            //     } else {
            //         this.pinia.logout()
            //     }
            // }
        },

        async login() {
            if (this.usuario == '' || this.contrasena == '') {
                return swal('warning', 'Ingrese usuario y contraseña')
            }

            const auth = {
                usuario: this.usuario,
                contrasena: this.contrasena,
            }

            this.spinBtnSignIn = true
            const data = await post(`${urls.usuarios}/login`, auth, 'Acceso correcto')
            this.spinBtnSignIn = false

            if (data.code != 0) return

            this.recordar ? localStorage.setItem('login_remember', this.usuario) : localStorage.removeItem('login_remember')
            this.useAuth.id = data.id
            this.useAuth.token = data.token
            this.usuario = ''
            this.contrasena = ''
            this.$router.replace({name:'home'})
        },
    }
}
</script>

<style lang="scss">
.login {
    height: 100vh;
    width: 100vw;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-color);

    .form-login{
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .card {
            border: var(--border);
            padding: 1.5rem 2rem;
            border-radius: 0.5rem;
            text-align: center;

            input[type=text], input[type=password] {
                width: 100%;
                padding: 1rem;
            }

            button {
                width: 100%;
                padding: 1rem 0.5rem;
            }
        }
    }
}

@media (max-width: 600px) {
    .login{
        .info{
            display: none;
        }
    }
}
</style>