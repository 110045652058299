<template>
    <div class="container-paqueteContenido">
        <div>
            <button class="btn btn2" @click="agregar"><i class="fa-solid fa-plus"></i></button>
        </div>
    
        <ul class="container-lista2" ref="container-lista">
            <li v-for="a in useData.servicio.para_ti" :key="a.id">
                <textarea rows="2" v-model="a.text"></textarea>

                <button class="btn btn2" @click="eliminar(a.id)"><i class="fa-solid fa-trash"></i></button>
            </li>
    
            <small v-if="useData.servicio.para_ti.length==0">No se ha agregado datos</small>
        </ul>
    </div>
    </template>
    
    <script>
    import { useData } from '@/store/data'
    
    import { deepCopy, generarId, scrollToBottom } from '@/store/util'
    
    export default {
        data: () => ({
            send:{},
    
            useData:useData(),
        }),
        methods: {
            agregar(){
                const send = {
                    id: generarId(this.useData.servicio.para_ti),
                    text: ''
                }

                this.useData.servicio.para_ti.push(deepCopy(send))
                scrollToBottom(this.$refs['container-lista'])
            },
            eliminar(id){
                const i = this.useData.servicio.para_ti.findIndex(a => a.id == id)
                this.useData.servicio.para_ti.splice(i, 1)
            },
        },
    }
</script>

<style lang="scss" scoped>
.container-paqueteContenido{
    display: flex;
    gap: 1rem;
    .container-lista2{
        max-height: 20rem;

        li{
            display: flex;
            gap: 0.5rem;
        }
    }
}
</style>