<template>
    <label class="container-checkbox">
        <input type="checkbox" v-model="inputModel" :disabled="disabled">
        <span :style="{fontSize:textSize+'rem'}">{{text}}</span>
    </label>
</template>

<script>
export default {
    props: {
        modelValue: Boolean,
        
        text: String,
        textSize: {type:String, default:'1'},
        disabled: {type:Boolean, default:false},
    },
    computed: {
        inputModel: {
            get() {
                return this.modelValue
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue)
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.container-checkbox{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    width: fit-content;

    input[type="checkbox"]{
        appearance: none;
        background-color: #fff;
        margin: 0;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 0.2rem;
        border: var(--border);
        display: grid;
        place-content: center;
        cursor: pointer;

        &:before {
            content: "";
            width: 0.7rem;
            height: 0.7rem;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            // box-shadow: inset 1rem 1rem var(--primary-color);
            // background-color: CanvasText;
            background-color: var(--primary-color);
            transform-origin: center;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        }

        &:checked::before {
            transform: scale(1);
        }

        &:disabled {
            background-color: var(--input-disabled-bg-color);
            cursor: not-allowed;
        }
    }
}
</style>