<template>
    <aside>
        <div>
            <h2>Consola</h2>
        </div>

        <div>
            <strong>Páginas</strong>

            <router-link :to="{name:'home'}" active-class="selected">Home</router-link>
            <router-link :to="{name:'servicios'}" active-class="selected">Servicios</router-link>
        </div>
    </aside>
</template>

<script>
export default {
    methods: {
    },
}
</script>

<style lang="scss" scoped>
aside{
    height: 100vh;
    border-right: var(--border);

    >div{
        border-bottom: var(--border);
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        padding: 1rem 1.5rem;

        a{
            padding: 0.8rem;
            border-radius: 0.5rem;

            &:hover{
                background-color: var(--hover-color2);
            }
        }
    }

    .selected{
        background-color: var(--selected) !important;
    }
}
</style>