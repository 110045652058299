import {defineStore} from "pinia"

export const useModals = defineStore('modals', {
    state: () => ({
        imageViewer: {show:false, link:''},

        dataComentariosCrear: {show:false, title:''},
        dataInstagramPostsCrear: {show:false, title:''},
        
        dataServiciosCrear: {show:false, title:''},
    }),
    actions: {

    },
    // persist: {
    //     storage: localStorage,
    //     paths: ['tablas']
    // }
})