<template>
    <div>
        <img src="@/assets/img/logo.png" :style="{height:height+'rem'}">
    </div>
</template>

<script>
export default {
    props: {
        height: {type:Number, default:3}
    }
}
</script>

<style lang="scss" scoped>
div{
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 1rem 0;

    img{
        // height: 3rem;
    }
}
</style>