<template>
  <router-view/>
</template>

<style lang="scss">
:root{
    --primary-color: #1ED760;
    --secondary-color: #ffffff;
    --bg-color: #ffffff;
    --bg-color2: whitesmoke;

    --text-color: black;
    --text-color2: #828282;
    --text-disabled-color: whitesmoke;


    --bg-disabled-color: #d1d0d0;
    // BUTTONS
    //--btn-color: #ffffff;
    // --btn-hover-color: #f1f2f5;
    //--btn1-color: #1ED760;
    --btn1-hover-color: #20ca5c;
    --btn2-color: #EBEDF0;
    --btn2-hover-color: #d9dadc;
    
    --option-selected-bg-color: #e5feee;

    // --error-color: #E63C43;
    // --success-color: #00AEB5;
    // --warning-color: #FFD173;
    // --color-neutro1: #2665f6;
    --border: solid 0.06rem #CCCCCC;
    --border-dashed: dashed 0.06rem #CCCCCC;

    --shadow-color: #d1d0d0;
    --hover-color: whitesmoke;
    --hover-color2: rgb(237, 237, 237);
    --selected: rgb(225, 225, 225);

    --modal-box-shadow: 0 0 0.5rem 0.1rem var(--shadow-color);

    --section-box-shadow: 0 0.15rem 0.2rem var(--shadow-color);

    // INPUT
    --input-bg-color: #ffffff;
    --input-disabled-bg-color: whitesmoke;
    --input-text-color: #3C3C3B;
    --input-disabled-text-color: #E0E0E0;
    --input-placeholder: #9c9c9c;
    --input-padding: 0.5rem 0.9rem;

    --dato-vacio-bg-color: #f1f2f5;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto","Arial",sans-serif;
  border: none;
  outline: none;
  color: var(--text-color);
  font-size: 15px;
}

h1{
    font-size: 1.4rem;
}

h2, h3 {
    font-size: 1.2rem;
}

p, strong{
    font-size: 1rem;
}

small{
    font-size: 0.8rem;
    color: var(--text-color2);
}

ul{
    padding: 0;
    margin: 0;
}

li{
    list-style: none;

    &:hover{
        .acts{
            opacity: 1;
        }
    }
}

a{
    text-decoration: none;
    // font-weight: bold;
    
    &:hover{
        // background-color: var(--secondary-color);
        // color: var(--primary-color);
    }
}

label{
    font-size: 0.9rem;
    margin-top: 0.5rem;
}

input[type=text],input[type=password],[type=search],input[type=number],input[type=email],input[type=date],input[type=time],select,textarea{
    padding: var(--input-padding);
    border: var(--border);
    border-radius: 0.4rem;
    font-size: 0.9rem;
    transition: border .2s;
    resize: none;
    background-color: var(--input-bg-color);
    color: var(--input-text-color);
    height: fit-content;
    width: 100%;

    &:focus{
        border-color: var(--primary-color);
    }

    &:disabled{
        opacity: 1;
        background-color: var(--input-disabled-bg-color) !important;
        color: var(--input-disabled-text-color) !important;
    }

    &::placeholder{
        color: var(--input-placeholder);
    }
}

input[type=radio]{
    cursor: pointer;
}

select{
    //padding: 1.095px 0.1rem;
    cursor: pointer;

    option{
        background-color: var(--input-bg-color);
        font-size: 0.9rem;
    }
}

.btn{
    width: fit-content;
    height: fit-content;
    border-radius: 0.4rem;
    padding: var(--input-padding);
    font-size: 1rem;
    cursor: pointer;
    // transition: background 0.2s;
    background-color: var(--bg-color);
    white-space: nowrap;
    font-weight: 500;

    *{
        font-size: 1rem;
    }

    // &:hover{
    //     background-color: var(--btn-hover-color);
    // }

    &:focus{
        box-shadow: 0 0 0.25rem 0.1rem var(--shadow-color);
    }

    &:disabled{
        // border-color: var(--text-disabled-color) !important;
        background-color: var(--bg-disabled-color) !important;
        color: var(--text-disabled-color) !important;

        *{
            color: var(--text-disabled-color) !important;
        }

        &:hover{
            cursor: not-allowed;
            background-color: var(--bg-disabled-color) !important;
            // color: var(--text-disabled-color) !important;

            // *{
            //     color: var(--text-disabled-color) !important;
            // }
        }
    }
}

.btn1{
    background-color: var(--primary-color);
    color: white;

    *{
        color: white;
    }

    &:hover{
        background-color: var(--btn1-hover-color);
    }
}

.btn2{
    background-color: var(--btn2-color);

    &:hover{
        background-color: var(--btn2-hover-color);
    }
}

.mrg-btm05{
    margin-bottom: 0.5rem;
}

.mrg-btm1{
    margin-bottom: 1rem;
}

.mrg-btm2{
    margin-bottom: 2rem;
}

.mrg-top1{
    margin-top: 1rem;
}
</style>
