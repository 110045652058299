<template>
    <section class="data-main">
        <article class="head mrg-btm2" v-if="!useModals.dataComentariosCrear.show">
            <h2>Comentarios</h2>
            <button class="btn btn1" @click="nuevo">Nuevo</button>
        </article>
        
        <dataComentariosLista v-if="!useModals.dataComentariosCrear.show"/>
        <dataComentariosCrear v-if="useModals.dataComentariosCrear.show"/>
    </section>
</template>

<script>
import dataComentariosLista from '@/views/home/dataComentariosLista.vue'
import dataComentariosCrear from '@/views/home/dataComentariosCrear.vue'

import { useModals } from '@/store/modals'
import { useData } from '@/store/data'

export default {
    components: {
        dataComentariosLista,
        dataComentariosCrear
    },
    data: () => ({
        useModals:useModals(),
        useData:useData(),
    }),
    methods: {
        nuevo(){
            this.useData.initComentario()
            this.useModals.dataComentariosCrear.title = 'Nuevo comentario'
            this.useModals.dataComentariosCrear.show = true
        },
    },
}
</script>