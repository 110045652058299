<template>
    <article>
        <div class="container-buscar mrg-btm1">
            <span>Buscar:</span>
            <input type="search" v-model="txtBuscar" @input="buscar()">
        </div>

        <ul class="container-lista">
            <li v-for="a in lista1" :key="a.id" @click="editar(a)">
                <div class="mrg-btm05">
                    <strong>{{ capitalizarPalabras(a.cliente_nombre) }}</strong>
                    <small>{{ moment(a.created_at).format('DD/MM/YYYY LT') }}</small>
                </div>

                <p>{{ a.comentario }}</p>
            </li>

            <small v-if="!listLoading && lista1.length==0">No se encuentran registros</small>

            <loadingSpin position="initial" v-if="listLoading"/>
        </ul>
    </article>
</template>

<script>
import loadingSpin from '@/components/loadingSpin.vue'

import { useData } from '@/store/data'
import { useModals } from '@/store/modals'

import { urls, get} from '@/store/crud'
import { deepCopy, capitalizarPalabras } from '@/store/util'

import moment from 'moment'

export default {
    components: {loadingSpin},
    data: () => ({
        lista1:[],
        txtBuscar:'',
        listLoading:false,

        useData:useData(),
        useModals:useModals(),

        capitalizarPalabras,

        moment,
    }),
    async mounted(){        
        if (this.useData.comentariosLd) {
            await this.load()
            this.useData.comentariosLd = false
        }
        else {
            this.buscar()
        }
    },
    methods: {
        async load(){
            this.listLoading = true
            const data = await get(`${urls.comentarios}`)
            this.listLoading = false

            if (data.code) return

            this.useData.comentarios = deepCopy(data)

            this.buscar()
        },
        editar(a){
            this.useData.comentario = deepCopy(a)

            this.useModals.dataComentariosCrear.title = 'Editar comentario'
            this.useModals.dataComentariosCrear.show = true
        },
        buscar(){
            this.lista1 = this.useData.comentarios.filter(a => {
                return a.comentario.toLowerCase().includes(this.txtBuscar.toLowerCase()) ||
                        a.cliente_nombre.toLowerCase().includes(this.txtBuscar.toLowerCase())

            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .container-lista{
        li{
            div{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
</style>