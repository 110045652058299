<template>
    <div class="container-inputImageLink">
        <input type="text" :placeholder="placeholder" v-model="inputModel">
        
        <button class="btn btn2" @click="openImage" :disabled="!isValidLink">
            <i class="fa-regular fa-image"></i>
        </button>
    </div>
</template>

<script>
import {useModals} from '@/store/modals'

export default {
    props: {
        modelValue: String,

        placeholder: {type:String, default:''},
    },
    data: () => ({
        useModals:useModals()
    }),
    computed: {
        inputModel: {
            get() {
                return this.modelValue
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue)
            },
        },

        isValidLink() {
            return /^(ftp|http|https):\/\/[^ "]+$/.test(this.inputModel)
        }
    },
    methods: {
        openImage(){
            this.useModals.imageViewer.link = this.inputModel
            this.useModals.imageViewer.show = true
        }
    },
}
</script>

<style lang="scss">
    .container-inputImageLink{
        display: flex;
        gap: 0.5rem;
    }
</style>