import Swal from 'sweetalert2'

const swal = (icon, title) => Swal.fire({icon, title, toast:true, position:'top-end', showConfirmButton:false, timer:2000, timerProgressBar:true})

function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj))
}

function incompleteData (a, p) {
    // p = solo propiedades a evaluar
    if (p === undefined) {
        for(let prop in a){
            if (a[prop] === "" || a[prop] === null || a[prop] === undefined) {
                console.log(prop)
                return true
            }
        }
    } else {
        for(let prop in a){
            if (p.includes(prop) && (a[prop] === "" || a[prop] === null || a[prop] === undefined)) {
                console.log(prop)
                return true
            }
        }
    }

    return false
}

function isEmail(texto) {
    const patron = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return patron.test(texto)
}

function capitalizarPalabras(texto) {
    if (texto == undefined) return ''
    
    const palabras = texto.toLowerCase().split(' ')

    const palabrasCapitalizadas = palabras.map(palabra => {
        return palabra.charAt(0).toUpperCase() + palabra.slice(1)
    })
  
    return palabrasCapitalizadas.join(' ');
}

const generarId = (array, prop) => {
    if (prop == undefined) prop = 'id'
    let id

    if (array.length == 0) {
        id = 1
    }
    else {
        const masAlto = array.reduce((max, objeto) => {
            if (objeto[prop] > max) return objeto[prop]
            return max
        }, 0)
        id = masAlto + 1
    }
    
    return id
}

const scrollToBottom = (element) => {
    setTimeout(() => {
        try {element.scrollTop = element.scrollHeight} catch (error) {error}
    }, 0)
}

function isLinkValid(uri) {
    return /^(ftp|http|https):\/\/[^ "]+$/.test(uri)
}

function openLink(link){
    window.open(link, '_blank')
}

export {
    swal, deepCopy, incompleteData, isEmail, capitalizarPalabras, generarId, scrollToBottom,
    isLinkValid, openLink,
}