<template>
    <section class="container-imageViewer">
        <button class="btn btn2" @click="useModals.imageViewer.show = false"><i class="fa-solid fa-xmark"></i></button>

        <img :src="useModals.imageViewer.link" alt="Imagen no encontrada">
    </section>
</template>

<script>
import { useModals } from '@/store/modals'

export default {
    data: () => ({
        useModals:useModals(),
    }),
    mounted() {
        window.addEventListener('keydown', this.hideComponent)
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.hideComponent)
    },
    methods: {
        hideComponent(event){
            if (event.key === "Escape") {
                this.useModals.imageViewer.show = false
            }
        }
    },
}
</script>

<style lang="scss">
.container-imageViewer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(black, 0.7);
    z-index: 1;

    button{
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    img{
        max-height: 70vh;
        max-width: 90vw;
        box-shadow: 0 0 0.7rem rgba(black, 0.7);
    }
}
</style>