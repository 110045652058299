<template>
    <div class="form-data" style="grid-template-columns: 10rem auto">
        <label class="datonec">Nombre</label>
        <input type="text" v-model="useData.servicioPaquete.name">

        <label class="datonec">Tiempo entrega</label>
        <input type="text" v-model="useData.servicioPaquete.tiempo">

        <label class="datonec">Precio (S/)</label>
        <input type="text" v-model="useData.servicioPaquete.soles">

        <label class="datonec">Precio ($)</label>
        <input type="text" v-model="useData.servicioPaquete.dolares">

        <label class="datonec">Nombre para wtsp</label>
        <input type="text" v-model="useData.servicioPaquete.wtsp">

        <label class="datonec">Link imagen</label>
        <inputImageLink v-model="useData.servicioPaquete.image"/>
    </div>
</template>

<script>
import inputImageLink from '@/components/inputImageLink.vue'

import { useData } from '@/store/data'

export default {
    components: {
        inputImageLink,
    },
    data: () => ({
        useData:useData(),
    }),
}
</script>