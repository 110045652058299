import { createRouter, createWebHistory } from 'vue-router'

import signIn from '@/views/signIn.vue'

import consoleView from '@/views/consoleView.vue'
  // PAGES
  import homeView from '@/views/home/homeView.vue'
    import dataComentarios from '@/views/home/dataComentarios.vue'
    import dataInstagramPosts from '@/views/home/dataInstagramPosts.vue'
  
  import serviciosView from '@/views/servicios/serviciosView.vue'
    import dataServicios from '@/views/servicios/dataServicios.vue'

const routes = [
  {path:'/', redirect:'/signin'},

  // {path:'/', redirect:'/signin'},

  {path:'/console', name:'console', component:consoleView, children:[
    {path:'pages', name:'pages', children:[
      {path:'home', name:'home', component:homeView, redirect:{name:'comentarios'}, children:[
        {path:'comentarios', name:'comentarios', component:dataComentarios},
        {path:'instagram-posts', name:'instagram-posts', component:dataInstagramPosts},
      ]},
      
      {path:'servicios', name:'servicios', component:serviciosView, redirect:{name:'servicios-list'}, children:[
        {path:'servicios-list', name:'servicios-list', component:dataServicios},
      ]},
    ]},
  ]},

  {path:'/signin', name:'signin', component:signIn},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router