<template>
    <article class="crear">
        <header class="mrg-btm2">
            <h2>{{ useModals.dataComentariosCrear.title }}</h2>
        </header>

        <div class="form-data" style="grid-template-columns: 10rem auto">
            <label>Cliente</label>
            <select v-model="useData.comentario.cliente">
                <option v-for="a in clientesList" :key="a.id" :value="a.id">{{capitalizarPalabras(a.nombre)}}</option>
            </select>

            <label>Comentario</label>
            <textarea cols="30" rows="8" v-model="useData.comentario.comentario"></textarea>
        </div>

        <div class="crear-footer">
            <buttonSpin text="Cancelar" :func="cancelar"/>
            <buttonSpin color="1" text="Crear" :func="crear" :spin="btnCrear" v-if="!useData.comentario.id"/>
            <buttonSpin text="Eliminar" :func="eliminar" :spin="btnEliminar" v-if="useData.comentario.id"/>
            <buttonSpin color="1" text="Modificar" :func="modificar" :spin="btnModificar" v-if="useData.comentario.id"/>
        </div>
    </article>
</template>

<script>
import buttonSpin from '@/components/buttonSpin.vue'

import { useAuth } from '@/store/auth'
import { useData } from '@/store/data'
import { useModals } from '@/store/modals'

import { urls, get, post, patch, delet } from '@/store/crud'
import { deepCopy, swal, incompleteData, capitalizarPalabras } from '@/store/util'

export default {
    components: {
        buttonSpin
    },
    data: () => ({
        clientesList:[],

        btnCrear:false,
        btnModificar:false,
        btnEliminar:false,

        useAuth:useAuth(),
        useData:useData(),
        useModals:useModals(),

        capitalizarPalabras,
    }),
    async mounted(){
        // this.loadClientes()
        const lsClientesList = localStorage.getItem('clientesList')
        this.clientesList = JSON.parse(lsClientesList)
    },
    unmounted() {
        this.cancelar()
    },
    methods: {
        cancelar(){
            this.useModals.dataComentariosCrear.show = false
        },
        async crear(){
            if (incompleteData(this.useData.comentario, ['cliente','comentario'])) return swal('warning', 'Ingrese cliente y comentario')

            this.useData.comentario.created_by = this.useAuth.id,

            this.btnCrear = true
            const data = await post(`${urls.comentarios}`, this.useData.comentario)
            this.btnCrear = false

            if (data.code != 0) return

            this.useData.comentario.id = data.id
            this.useData.comentario.created_at = data.created_at
            this.useData.comentario.last_update = data.last_update
            this.useData.comentario.cliente_nombre = this.clientesList.find(a => a.id == this.useData.comentario.cliente).nombre
            
            this.useData.comentarios.push(this.useData.comentario)
            this.cancelar()
        },
        async modificar(){
            if (incompleteData(this.useData.comentario, ['cliente','comentario'])) return swal('warning', 'Ingrese cliente y comentario')

            this.btnModificar = true
            const data = await patch(`${urls.comentarios}`, this.useData.comentario)
            this.btnModificar = false

            if (data.code != 0) return
            
            this.useData.comentario.last_update = data.last_update
            this.useData.comentario.cliente_nombre = this.clientesList.find(a => a.id == this.useData.comentario.cliente).nombre

            const i = this.useData.comentarios.findIndex(a => a.id == this.useData.comentario.id)
            this.useData.comentarios.splice(i, 1, this.useData.comentario)
            this.cancelar()
        },
        async eliminar(){
            this.btnEliminar = true
            const data = await delet(`${urls.comentarios}`, this.useData.comentario)
            this.btnEliminar = false

            if (data.code != 0) return

            const i = this.useData.comentarios.findIndex(a => a.id == this.useData.comentario.id)
            this.useData.comentarios.splice(i, 1)
            this.cancelar()
        },



        //// ----- OTROS DATOS ----- ////
        async loadClientes(){  
            const data = await get(`${urls.clientes}`)

            if (data.code) return

            this.clientesList = deepCopy(data)

            localStorage.setItem('clientesList', JSON.stringify(this.clientesList))
        },
    },
}
</script>