<template>
    <section class="data-main">
        <article class="head mrg-btm2" v-if="!useModals.dataServiciosCrear.show">
            <h2>Servicios</h2>
            <button class="btn btn1" @click="nuevo">Nuevo</button>
        </article>

        <dataServiciosLista v-if="!useModals.dataServiciosCrear.show"/>
        <dataServiciosCrear v-if="useModals.dataServiciosCrear.show"/>
    </section>
</template>

<script>
import dataServiciosLista from '@/views/servicios/dataServiciosLista.vue'
import dataServiciosCrear from '@/views/servicios/dataServiciosCrear.vue'

import { useModals } from '@/store/modals'
import { useData } from '@/store/data'

export default {
    components: {dataServiciosLista, dataServiciosCrear},
    data: () => ({
        useModals:useModals(),
        useData:useData(),
    }),
    methods: {
        nuevo(){
            this.useData.initServicio()
            this.useModals.dataServiciosCrear.title = 'Nuevo servicio'
            this.useModals.dataServiciosCrear.show = true
        },
    },
}
</script>

<style lang="scss" scoped>
// .comentarios{
//     .container-lista{
//         >div{
//             display: flex;
//             gap: 1rem;
//             align-items: center;
//         }

//         ul{
//             display: flex;
//             flex-direction: column;
//             gap: 1rem;
//             overflow-y: auto;

//             li{
//                 padding: 1rem;
//                 border-radius: 0.5rem;
//                 cursor: pointer;
//                 display: flex;
//                 gap: 1rem;

//                 &:hover{
//                     background-color: var(--hover-color);
//                 }

//                 img{
//                     width: 2rem;
//                 }

//                 div{
//                     width: 100%;
//                     display: flex;
//                     justify-content: space-between;
//                     align-items: center;
//                 }
//             }
//         }
//     }
// }
</style>