<template>
<div class="container-paquetes">
    <div>
        <div class="botones mrg-btm1">
            <button class="btn btn2" v-if="mode == 1" @click="nuevo">Nuevo</button>

            <div class="botones" v-if="mode == 2">
                <button class="btn btn2" @click="cancelar">Cancelar</button>
                <button class="btn btn2" v-if="!edit" @click="agregar">Agregar</button>
                <button class="btn btn2" v-if="edit" @click="eliminar">Eliminar</button>
                <button class="btn btn2" v-if="edit" @click="modificar">Modificar</button>
            </div>
        </div>

        <ul class="container-lista2" ref="container-lista" v-if="mode == 1">
            <li v-for="(a) in useData.servicio.packs"
                :key="a.id" @click="editar(a)"
            >
                <img :src="a.image">

                <div>
                    <div class="mrg-btm1">
                        <strong>{{ capitalizarPalabras(a.name) }}</strong>
                    </div>

                    <div>
                        <div>
                            <small>Tiempo entrega</small>
                            <p>{{ a.tiempo }}</p>
                            <small>Precio (S/)</small>
                            <p>{{ a.soles }}</p>
                            <small>Precio ($)</small>
                            <p>{{ a.dolares }}</p>
                        </div>

                        <div>
                            <small>Contenido</small>
                            <p>{{ a.contenido.length }}</p>
                            <small>Regalo</small>
                            <p>{{ a.regalo.length }}</p>
                            <small>Incluye</small>
                            <p>{{ a.incluye.length }}</p>
                        </div>
                    </div>
                </div>
            </li>

            <small v-if="useData.servicio.packs.length==0">No se ha agregado datos</small>
        </ul>

        <div v-if="mode == 2">
            <div class="mrg-btm2">
                <div class="mrg-btm05 sub-container-title"><strong>General:</strong></div>
                <dataServiciosCrearPaquetesGeneral/>
            </div>

            <div class="mrg-btm2">
                <div class="mrg-btm05 sub-container-title"><strong>Contenido:</strong></div>
                <dataServiciosCrearPaquetesContenido/>
            </div>

            <div class="mrg-btm2">
                <div class="mrg-btm05 sub-container-title"><strong>Regalo:</strong></div>
                <dataServiciosCrearPaquetesRegalo/>
            </div>

            <div class="mrg-btm2">
                <div class="mrg-btm05 sub-container-title"><strong>Incluye:</strong></div>
                <dataServiciosCrearPaquetesIncluye/>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import dataServiciosCrearPaquetesGeneral from './dataServiciosCrearPaquetesGeneral.vue'
import dataServiciosCrearPaquetesContenido from './dataServiciosCrearPaquetesContenido.vue'
import dataServiciosCrearPaquetesRegalo from './dataServiciosCrearPaquetesRegalo.vue'
import dataServiciosCrearPaquetesIncluye from './dataServiciosCrearPaquetesIncluye.vue'

import { useData } from '@/store/data'

import { deepCopy, incompleteData, swal, generarId, scrollToBottom, capitalizarPalabras } from '@/store/util'

export default {
    components: {
        dataServiciosCrearPaquetesGeneral,
        dataServiciosCrearPaquetesContenido,
        dataServiciosCrearPaquetesRegalo,
        dataServiciosCrearPaquetesIncluye,
    },
    data: () => ({
        mode:1,
        edit:false,

        useData:useData(),

        capitalizarPalabras
    }),
    created() {
        this.cancelar()
    },
    methods: {
        nuevo(){
            this.useData.initServicioPaquete()
            this.edit = false
            this.mode = 2
        },
        cancelar(){
            this.mode = 1
        },
        checkDatos(){
            const item = deepCopy(this.useData.servicioPaquete)
            
            let llenos = ['name','tiempo','soles','dolares','wtsp','image']

            if (incompleteData(item, llenos)) {
                swal('warning', 'Ingrese los datos necesarios (*)')
                return false
            }

            for (const a of item.contenido)
            {
                if (incompleteData(a, ['text'])) {
                    swal('warning', 'Campos vacíos en "Contenido"')
                    return false
                }
            }

            for (const a of item.regalo)
            {
                if (incompleteData(a, ['text'])) {
                    swal('warning', 'Campos vacíos en "Regalo"')
                    return false
                }
            }

            for (const a of item.incluye)
            {
                if (incompleteData(a, ['text'])) {
                    swal('warning', 'Campos vacíos en "Incluye"')
                    return false
                }
            }

            return true
        },
        agregar(){
            if (this.checkDatos() == false) return

            this.useData.servicioPaquete.id = generarId(this.useData.servicio.packs)
            this.useData.servicio.packs.push(deepCopy(this.useData.servicioPaquete))
            scrollToBottom(this.$refs['container-lista'])

            this.cancelar()
        },
        editar(a){
            this.useData.servicioPaquete = deepCopy(a)
            this.edit = true
            this.mode = 2
        },
        modificar(){
            if (this.checkDatos() == false) return

            const i = this.useData.servicio.packs.findIndex(a => a.id == this.useData.servicioPaquete.id)
            this.useData.servicio.packs.splice(i, 1, this.useData.servicioPaquete)

            this.cancelar()
        },
        eliminar(){
            const i = this.useData.servicio.packs.findIndex(a => a.id == this.useData.servicioPaquete.id)
            this.useData.servicio.packs.splice(i, 1)

            this.cancelar()
        },
    },
}
</script>

<style lang="scss" scoped>
.container-paquetes{
    .container-lista2{
        li{
            display: grid;
            grid-template-columns: 5rem auto;
            gap: 1rem;
    
            img{
                width: 100%;
                object-fit: cover;
            }
    
            >div{
                >div:nth-child(1){
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                >div:nth-child(2){
                    display: flex;
                    gap: 6rem;

                    div{
                        display: grid;
                        align-items: flex-end;
                        gap: 0.5rem 1rem;
                        grid-template-columns: auto auto;
                    }
                }
            }
        }
    }

    .sub-container-title{
        display: flex;
        align-items: center;
        gap: 1rem;

        &:before{
            content: '';
            display: inline-flex;
            width: 2rem;
            border-bottom: var(--border);
        }

        &:after{
            content: '';
            display: inline-flex;
            width: 100%;
            border-bottom: var(--border);
        }
    }
}
</style>