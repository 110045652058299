import {defineStore} from "pinia"

export const useData = defineStore('data', {
    state: () => ({
        comentario: null,
        comentarios: [],
        comentariosLd: true,

        instagramPost: null,
        instagramPosts: [],
        instagramPostsLd: true,

        servicio: null,
        servicios: [],
        serviciosLd: true,
        servicioPaquete: null,
    }),
    actions: {
        initComentario(){
            this.comentario = {id:'', created_at:'', created_by:'', last_update:'', cliente:'', comentario:''}
        },
        initInstagramPost(){
            this.instagramPost = {id:'', created_at:'', created_by:'', last_update:'', image:'', link:''}
        },

        initServicio(){
            this.servicio = {id:'', created_at:'', created_by:'', last_update:'', name:'', info:'', uri:'', image:'', portada:'', packs:[], para_ti:[], para_ti_no:[], proceso:[]}
        },
        initServicioPaquete(){
            this.servicioPaquete = {name:'', tiempo:'', soles:'', dolares:'', wtsp:'', image:'', contenido:[], regalo:[], incluye:[]}
        }
    },
    persist: {
        storage: localStorage,
        paths: ['comentarios', 'instagramPosts', 'servicios']
    }
})