<template>
    <article>
        <ul class="container-lista">
            <li v-for="a in lista1" :key="a.id" @click="editar(a)">
                <img :src="a.image" loading="lazy">

                <div>
                    <strong>{{ a.link }}</strong>
                    <small>{{ moment(a.created_at).format('DD/MM/YYYY LT') }}</small>
                </div>
            </li>

            <small v-if="!listLoading && lista1.length==0">No se encuentran registros</small>

            <loadingSpin position="initial" v-if="listLoading"/>
        </ul>
    </article>
</template>

<script>
import loadingSpin from '@/components/loadingSpin.vue'

import { useData } from '@/store/data'
import { useModals } from '@/store/modals'

import { urls, get} from '@/store/crud'
import { deepCopy } from '@/store/util'

import moment from 'moment'

export default {
    components: {loadingSpin},
    data: () => ({
        lista1:[],
        listLoading:false,

        useData:useData(),
        useModals:useModals(),

        moment,
    }),
    async mounted(){
        if (this.useData.instagramPostsLd) {
            await this.load()
            this.useData.instagramPostsLd = false
        }
        else {
            this.buscar()
        }
    },
    methods: {
        async load(){
            this.listLoading = true
            const data = await get(`${urls.instagram}`)
            this.listLoading = false

            if (data.code) return

            this.useData.instagramPosts = deepCopy(data)

            this.buscar()
        },
        editar(a){
            this.useData.instagramPost = deepCopy(a)

            this.useModals.dataInstagramPostsCrear.title = 'Editar post'
            this.useModals.dataInstagramPostsCrear.show = true
        },
        buscar(){
            this.lista1 = deepCopy(this.useData.instagramPosts)
        },
    },
}
</script>

<style lang="scss" scoped>
.container-lista{
    li{
        display: flex;
        gap: 1rem;

        img{
            width: 2rem;
        }

        div{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
</style>