<template>
    <main>
        <nav>
            <router-link :to="{name:'servicios-list'}" active-class="selected">Servicios</router-link>
        </nav>

        <div>
            <router-view/>
        </div>
    </main>
</template>

<script>

export default {

}
</script>

<style lang="scss" scoped>
main{
    overflow-y: auto;
    overflow-x: hidden;
    // height: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    nav{
        position: sticky;
        top: 0;
        width: 100%;
        padding: 1rem 2.5rem;
        background-color: var(--bg-color2);
        border-bottom: var(--border);
        z-index: 1;
        display: flex;
        gap: 1rem;

        a{
            padding: 0.5rem 0.8rem;
            border-radius: 0.5rem;

            &:hover{
                background-color: var(--hover-color2);
            }
        }
        .selected{
            background-color: var(--selected) !important;
        }
    }

    >div{
        padding: 1rem;
    }
}
</style>